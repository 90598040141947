import { EnhancedLabeledSelect } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounceFunction } from '../../../hooks/useDebounce';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { useLoading } from '../../../hooks/useLoading';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { getDeviceBrandsList } from '../../../services/deviceService';
import { DeviceBrand } from '../../../types/deviceBrand';

type Props = {
  deviceBrand?: DeviceBrand;
  selectedDeviceBrandId?: string;
  onChangeDeviceBrand: (deviceBrandId?: string) => void;
  isFilter?: boolean;
  label?: string;
  inline?: boolean;
  required?: boolean;
};
export function DeviceBrandSelect({
  selectedDeviceBrandId,
  onChangeDeviceBrand,
  deviceBrand,
  isFilter = true,
  label = 'Marca:',
  inline = false,
  required = false,
}: Readonly<Props>) {
  const [devicesBrandsList, setDevicesBrandsList] = useState<DeviceBrand[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { search, setSearch } = useDebouncedSearch(useState<string>());
  const [selectedDeviceBrandOption, setSelectedDeviceBrandOption] = useState<{ id: string; label: string }>();
  const requestIdRef = useRef(0);

  useUpdateEffect(() => {
    setPage(0);
  }, [search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadDevicesBrands = async () => {
      const requestId = ++requestIdRef.current;
      const { brands, error, hasNext } = await getDeviceBrandsList(page, search);
      if (requestId === requestIdRef.current) {
        if (!error && brands) {
          if (page > 1 && devicesBrandsList) {
            setDevicesBrandsList([...devicesBrandsList, ...brands]);
            setHasNextPage(hasNext);
          } else {
            setDevicesBrandsList(brands);
            setHasNextPage(hasNext);
          }
        } else {
          setDevicesBrandsList(undefined);
          setHasNextPage(false);
        }
        stopLoading();
      }
    };
    loadDevicesBrands();
  }, [page]);

  const deviceBrandOptions = useMemo(() => {
    if (!devicesBrandsList) return;
    const devicesBrandsOptions: { id: string | undefined; label: string }[] = devicesBrandsList.map((deviceBrand) => ({
      id: deviceBrand._id,
      label: deviceBrand.name,
    }));
    if (!required) {
      devicesBrandsOptions?.unshift({
        id: undefined,
        label: isFilter ? 'Todos' : 'Ninguna',
      });
    }
    if (deviceBrand) {
      if (!devicesBrandsOptions.find((c) => c.id === deviceBrand._id))
        devicesBrandsOptions.push({
          id: deviceBrand._id,
          label: deviceBrand.name,
        });
    }
    if (selectedDeviceBrandOption) {
      if (!devicesBrandsOptions.find((c) => c.id === selectedDeviceBrandOption.id))
        devicesBrandsOptions.push(selectedDeviceBrandOption);
    }
    return devicesBrandsOptions;
  }, [devicesBrandsList]);

  const onDeviceBrandChange = (params: OnChangeParams) => {
    params.option?.id ? onChangeDeviceBrand(params.option.id as string) : onChangeDeviceBrand(undefined);
    setSelectedDeviceBrandOption(params.option as { id: string; label: string });
    setSearch(undefined);
  };

  const fetchData = () => {
    if (hasNextPage) setPage(page + 1);
  };

  const handleInputChange = useDebounceFunction(function (term: string) {
    setSearch(term);
  }, 500);

  return (
    <EnhancedLabeledSelect
      label={label}
      options={deviceBrandOptions}
      value={[{ id: selectedDeviceBrandId }]}
      onChange={onDeviceBrandChange}
      onInputChange={(e) => {
        handleInputChange(e.target.value);
      }}
      fullWidth
      infiniteScroll
      fetchData={fetchData}
      fetchDataLoading={loading}
      hasNext={hasNextPage}
      onBlur={() => setSearch(undefined)}
      searchable
      inline={inline}
      inset={inline}
      required={required}
    />
  );
}
