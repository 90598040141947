import { EmptyState, FlexColumn, StyledSearchBar } from '@gorila-shared-ui/components';
import { Block } from 'baseui/block';
import { useState } from 'react';
import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';
import useEffectOnce from '../../hooks/useEffectOnce';
import { useLoading } from '../../hooks/useLoading';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getAssetsSearch } from '../../services/assetService';
import { PaginatedResponse } from '../../types/api';
import { Asset } from '../../types/asset';
import SearchableAssetItem from './SearchableAssetItem';
import { VirtualizedPaginatedList } from './VirtualizedPaginatedList';

type Props = {
  clientId?: string;
  subClientId?: string;
  assetIds: string[];
};

export function SearchableAssetList({ assetIds, clientId, subClientId }: Props) {
  const [search, setSearch] = useState<string>();
  const { search: debouncedSearch, setSearch: setDebouncedSearch } = useDebouncedSearch([search, setSearch]);
  const [page, setPage] = useState(1);
  const [paginated, setPaginated] = useState<PaginatedResponse<Asset>>();
  const { loading, startLoading, stopLoading } = useLoading();

  const load = async () => {
    startLoading();
    const { hasNext, total, assets, error } = await getAssetsSearch(clientId, subClientId, page, search, assetIds);
    if (!error && assets) {
      if (page > 1) {
        setPaginated((prev) => ({
          hasNext: hasNext,
          total: total,
          items: [...prev!.items, ...assets],
        }));
      } else {
        setPaginated({
          hasNext,
          total,
          items: assets,
        });
      }
    } else {
      setPaginated(undefined);
    }
    stopLoading();
  };

  useEffectOnce(() => {
    load();
  });

  useUpdateEffect(() => {
    setPage(0);
  }, [search]);

  useUpdateEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }

    load();
  }, [page]);

  return (
    <FlexColumn>
      <StyledSearchBar
        value={debouncedSearch}
        onChange={setDebouncedSearch}
      />
      <Block height={'300px'}>
        {!!paginated?.items.length && (
          <VirtualizedPaginatedList
            elements={paginated?.items}
            loading={loading}
            onNextPage={setPage}
            paginatedResponse={paginated}
            itemComponent={SearchableAssetItem}
          />
        )}
        {!paginated?.items.length && (
          <EmptyState
            title="No se encontraron resultados"
            description="Intenta con otra busqueda"
          />
        )}
      </Block>
    </FlexColumn>
  );
}
