import { ContentContainer } from '@gorila-shared-ui/components';
import { useState } from 'react';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { ClientsFiltersBar } from '../../clients/ClientsFiltersBar';
import { ClientsList } from '../../clients/ClientsList';

export function ClientsPage() {
  const { search, setSearch } = useDebouncedSearch(useState<string>());
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <ClientsFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
      />
      <ClientsList
        search={search}
        reloadPage={reloadPage}
      />
    </ContentContainer>
  );
}
