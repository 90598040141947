import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SizeProp } from 'baseui/modal';
import { KIND as ButtonKind } from 'baseui/button';

type Props = {
  content: React.ReactNode;
  isOpen: boolean;
  closeable?: boolean;
  size?: SizeProp;
  title?: string;
  onAccept?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
};

export function StyledModal({
  content,
  onAccept,
  onCancel,
  onClose,
  title,
  closeable = false,
  isOpen = false,
  size = 'default',
}: Props) {
  return (
    <Modal onClose={onClose} closeable={closeable} isOpen={isOpen} animate autoFocus size={size}>
      {title && <ModalHeader>{title}</ModalHeader>}
      <ModalBody>{content}</ModalBody>
      {(onCancel || onAccept) && (
        <ModalFooter>
          {onCancel && (
            <ModalButton kind={ButtonKind.tertiary} onClick={onCancel}>
              Cancel
            </ModalButton>
          )}
          {onAccept && <ModalButton onClick={onAccept}>Okay</ModalButton>}
        </ModalFooter>
      )}
    </Modal>
  );
}
