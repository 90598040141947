import { EmptyState, FlexColumn, Loading, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { COMMS_REPORT_OFFLINE_TABLE_COLUMNS_NAME } from '../../constants/reports';
import { useFeedback } from '../../hooks/useFeedback';
import { useLoading } from '../../hooks/useLoading';
import { useMoment } from '../../hooks/useMoment';
import { useStyles } from '../../hooks/useStyles';
import { downloadReportFile, getReportsOffline } from '../../services/reportsService';
import { commsDateRangeState } from '../../storage/reports';
import { CommunicationsReportOffline } from '../../types/report';
import { StyledPaginatedTable } from '../shared/StyledPaginatedTable';

type Props = {
  reloadPage?: boolean;
};
export function CommsReportOfflineList({ reloadPage }: Readonly<Props>) {
  const { css, classes, theme } = useStyles();
  const [commsReportsOffline, setCommsReportsOffline] = useState<CommunicationsReportOffline[]>();
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const { loading, startLoading, stopLoading } = useLoading();
  const { formatDateFromTs } = useMoment();
  const commsDateRange = useRecoilValue(commsDateRangeState);
  const { showFailFeedback } = useFeedback();

  const validCommsDateRange = !!commsDateRange ? !!commsDateRange[0] && !!commsDateRange[1] : true;

  useEffect(() => {
    if (!validCommsDateRange) return;
    setPage(0);
  }, [reloadPage, validCommsDateRange]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadCommunicationsReportTableData = async () => {
      if (!validCommsDateRange) return;
      const response =
        validCommsDateRange && !!commsDateRange
          ? await getReportsOffline(commsDateRange[0]!.toString(), commsDateRange[1]!.toString(), page)
          : await getReportsOffline(undefined, undefined, page);
      if (response?.error) {
        setCommsReportsOffline(undefined);
        showFailFeedback(response.error);
        setTotalItems(0);
      } else {
        setCommsReportsOffline(response?.reportsOffline);
        setTotalItems(response?.total ?? 0);
      }
      stopLoading();
    };
    loadCommunicationsReportTableData();
  }, [page]);

  const downloadReport = async (reportKey: string) => {
    const { url, error } = await downloadReportFile(reportKey);
    if (!error && url) {
      window.open(url, '_blank');
    } else {
      showFailFeedback(error || 'Ocurrió un error al descargar el reporte, inténtalo más tarde.');
    }
  };

  return (
    <FlexColumn classNames={`${classes.flexFill} ${css({ overflow: 'hidden' })}`}>
      {!validCommsDateRange && <EmptyState title="Selecciona un rango de fechas válido para generar el reporte" />}
      {validCommsDateRange && (
        <>
          {!commsReportsOffline && loading && <Loading />}
          {commsReportsOffline && (
            <>
              {!!commsReportsOffline?.length && (
                <StyledPaginatedTable
                  columns={COMMS_REPORT_OFFLINE_TABLE_COLUMNS_NAME}
                  data={commsReportsOffline.map((communicationsReport, i) => {
                    return {
                      items: [
                        communicationsReport.from ? formatDateFromTs(+communicationsReport.from, 'DD/MM/yyyy') : '-',
                        communicationsReport.to ? formatDateFromTs(+communicationsReport.to, 'DD/MM/yyyy') : '-',
                        communicationsReport.status,
                        communicationsReport.created
                          ? formatDateFromTs(+communicationsReport.created, 'DD/MM/yyyy')
                          : '-',
                        <StyledTooltip
                          key={`ws-action-${i}`}
                          content={'Descargar'}
                          showArrow={false}
                        >
                          <Button
                            size="compact"
                            shape="square"
                            onClick={() => downloadReport(communicationsReport._id)}
                            kind="tertiary"
                            disabled={communicationsReport.status === 'Pendiente'}
                          >
                            <MaterialIcon name={'download'} color={communicationsReport.status === 'Pendiente' ? theme.colors.inputTextDisabled : undefined}/>
                          </Button>
                        </StyledTooltip>,
                      ],
                    };
                  })}
                  totalItems={totalItems}
                  page={page}
                  onPageChange={setPage}
                  loading={loading}
                />
              )}
              {!commsReportsOffline.length && (
                <EmptyState
                  title="No se encontraron resultados"
                  description="Intenta con otra busqueda"
                />
              )}
            </>
          )}
        </>
      )}
    </FlexColumn>
  );
}
