import { FlexRow, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useRecoilState } from 'recoil';
import { useStyles } from '../../hooks/useStyles';
import { commsDownloadState } from '../../storage/reports';
import { CommsReportFilters } from './CommsReportFilters';

type Props = {
  onReloadPage: () => void;
  enabledDownload?: boolean;
};
export function CommsReportFiltersBar({ onReloadPage, enabledDownload = false }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const [commsDownload, setCommsDownload] = useRecoilState(commsDownloadState);

  return (
    <FlexRow
      classNames={css({
        justifyContent: 'space-between',
        width: '100%',
      })}
    >
      <FlexRow
        gap={theme.sizing.scale300}
        classNames={css({
          width: '70%',
        })}
      >
        <CommsReportFilters />
      </FlexRow>
      <FlexRow gap={theme.sizing.scale300}>
        {enabledDownload && (
          <StyledTooltip
            content={'Descargar'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={() => setCommsDownload(true)}
              kind="tertiary"
              isLoading={commsDownload}
            >
              <MaterialIcon name={'download_for_offline'} />
            </Button>
          </StyledTooltip>
        )}
        <StyledTooltip
          content={'Recargar tabla'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onReloadPage}
            kind="tertiary"
          >
            <MaterialIcon name={'refresh'} />
          </Button>
        </StyledTooltip>
      </FlexRow>
    </FlexRow>
  );
}
