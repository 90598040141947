import { ContentContainer } from '@gorila-shared-ui/components';
import { useState } from 'react';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { WebServiceLogList } from '../../logs/WebServiceLogList';
import { WebServiceLogsFiltersBar } from '../../logs/WebServiceLogsFiltersBar';

export function WebServiceLogsPage() {
  const { search, setSearch } = useDebouncedSearch(useState<string>());
  const [reloadPage, setReloadPage] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState<string>();
  const [selectedSubClientId, setSelectedSubClientId] = useState<string>();

  return (
    <ContentContainer>
      <WebServiceLogsFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
        selectedClientId={selectedClientId}
        selectedSubClientId={selectedSubClientId}
        setSelectedClientId={setSelectedClientId}
        setSelectedSubClientId={setSelectedSubClientId}
      />
      <WebServiceLogList
        search={search}
        reloadPage={reloadPage}
        selectedClientId={selectedClientId}
        selectedSubClientId={selectedSubClientId}
      />
    </ContentContainer>
  );
}
