import { SetterOrUpdater } from 'recoil';

export function useForms<T1, T2>(setForm: SetterOrUpdater<T1>, setFormTouched: SetterOrUpdater<T2>) {
  const onChangeInput = (key: keyof T1, value: any) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onTouched = (key: keyof T2) => {
    setFormTouched((prev) => ({
      ...prev,
      [key]: true,
    }));
  };

  return {
    onChangeInput,
    onTouched,
  };
}
